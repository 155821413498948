
import GLazyImage from '../g-lazy-image';
import GStars from '../g-stars';

export default {
  name: 'g-company-rating',
  components: {
    GLazyImage,
    GStars,
  },
  props: {
    ratingContent: {
      type: Array,
      required: true,
    },
    showRatingHorizontally: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    starSize() {
      return this.$isMobile ? 'medium' : 'large';
    },
  },
  methods: {
    scoreFinal(content) {
      const score = content?.content?.score;
      const scoreMax = content?.content?.score_max;
      const totalReview = content?.content?.total_review;
      if (this.showRatingHorizontally) {
        return `${score}/${scoreMax}`;
      } else {
        return this.$isMobile
          ? `${score}/${scoreMax} (${totalReview})`
          : `${score}/${scoreMax}`;
      }
    },
  },
};
