
import GChecklist from '../g-checklist';
import GButtonV2 from '../g-button-v2';

export default {
  name: 'g-card-highlight-price',
  components: {
    GChecklist,
    GButtonV2,
  },
  props: {
    tag: {
      type: String,
      required: false,
      default: '',
    },
    heading: {
      type: String,
      required: true,
    },
    subheading: {
      type: String,
      required: true,
    },
    secondSubheading: {
      type: String,
      required: false,
      default: '',
    },
    featuresList: {
      type: Array,
      required: true,
    },
    ctaText: {
      type: String,
      required: true,
    },
    ctaLink: {
      type: String,
      required: true,
    },
    ctaType: {
      type: String,
      required: true,
    },
    secondaryCtaUrl: {
      type: String,
      default: '',
    },
    preSecondaryCtaText: {
      type: String,
      default: '',
    },
    secondaryCtaText: {
      type: String,
      default: '',
    },
    taxTerms: {
      type: String,
      required: false,
      default: '',
    },
    displayableTerms: {
      type: String,
      required: false,
      default: '',
    },
    currency: {
      type: String,
      required: false,
      default: '',
    },
    finalPrice: {
      type: String,
      default: '',
    },
    finalPriceInactive: {
      type: String,
      required: true,
      default: '',
    },
    plan: {
      type: String,
      required: true,
    },
    ipCountry: {
      type: String,
      default: 'us',
    },
  },
  data() {
    return {
      modalTargetMappings: {
        signup: '#signupModal',
        consultation: '#bookADemo',
      },
    };
  },
  computed: {
    getTerms() {
      const currencyTerms = `${this.currency} ${this.taxTerms}`;

      if (this.plan === 'flexi') {
        return `
      per active user/week <br />
      ${currencyTerms} <br />
      ${this.finalPriceInactive} per inactive user/week
      ${this.taxTerms}
    `;
      }
      if (
        this.plan === 'hr_addon' ||
        this.plan === 'employee_onboarding' ||
        this.plan === 'hr_flexi'
      ) {
        return `
        ${currencyTerms} <br />
        ${this.displayableTerms}`;
      }
      return `
      per user per month billed monthly<br />
      ${currencyTerms}`;
    },
  },
  methods: {
    getModalTarget(buttonType) {
      if (typeof this.modalTargetMappings[buttonType] !== 'undefined') {
        return this.modalTargetMappings[buttonType];
      }
      return '';
    },
  },
};
