
import GLazyImage from '../g-lazy-image';

export default {
  name: 'g-colour-heading-card',
  components: {
    GLazyImage,
    ClockIcon: () => import('assets/svg/icons/icon-filled-clock.svg'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    colourRgb: {
      type: String,
      required: true,
      validator: color => color.includes('rgb('),
    },
    colourLightRgb: {
      type: String,
      required: true,
      validator: color => color.includes('rgb('),
    },
    imageData: {
      type: Object,
      default: null,
    },
    number: {
      type: Number,
      required: false,
      default: null,
    },
    minsToComplete: {
      type: String,
      required: false,
      default: '',
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    backgroundColor() {
      return this.highlighted ? '#fff' : this.colourLightRgb;
    },
  },
};
