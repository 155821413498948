
import richtext from '~/utils/richtext';

export default {
  name: 'general-exit-pop-up-modal',
  components: {
    GLazyImage: () => import('./goose/g-lazy-image'),
    GSignupForm: () => import('./goose/g-signup-form'),
  },
  computed: {
    exitPopUpProps() {
      return {
        heading: richtext(this.$store.state.modal.modalData.heading, true),
        subheading: richtext(
          this.$store.state.modal.modalData.subheading,
          true
        ),
        image: this.$store.state.modal.modalData.image,
      };
    },
    signupFormProps() {
      return {
        ctaText: this.$store.state.showFreemium
          ? this.$store.state.modal.signupModalData.cta_text_freemium
          : this.$store.state.modal.modalData.cta_text,
        formId: this.$store.state.modal.modalData.form_id,
        inline: false,
      };
    },
  },
};
