
import GToggleIcon from '../g-toggle-icon';
import GLazyImage from '../g-lazy-image';
import GSpacer from '../g-spacer';
import GStatsQuote from '../g-stats-quote';
import parseLinkHref from '~/utils/parseLinkHref';
import Vue from 'vue';

export default {
  name: 'g-accordion',
  components: {
    GToggleIcon,
    GLazyImage,
    GSpacer,
    GStatsQuote,
  },
  props: {
    accordion: {
      type: Array,
      required: true,
    },
    closeInactiveItems: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      initial: 0,
      activeState: {},
    };
  },
  created() {
    this.accordion.forEach((item, index) => {
      Vue.set(this.activeState, index, false);
    });
    this.activeState[this.initial] = true;
  },
  methods: {
    itemClicked(index) {
      if (this.closeInactiveItems) {
        if (this.activeState[index] === false) {
          // close all other items
          Object.keys(this.activeState).forEach(index => {
            this.closeItem(index);
          });
          this.openItem(index);
        }
      } else {
        this.toggleItem(index);
      }
    },
    toggleItem(index) {
      this.activeState[index] = !this.activeState[index];
    },
    openItem(index) {
      this.activeState[index] = true;
      // emit most recently opened item
      this.$emit('accordion-changed', index);
    },
    closeItem(index) {
      this.activeState[index] = false;
    },
    parseLinkHref,
  },
};
