
import { convertStoryblokCTAs } from '~/utils/storyblokHelpers';
import GButtonV2 from '../g-button-v2';
import GStars from '../g-stars';
import richtext from '~/utils/richtext';

export default {
  name: 'g-cta-light',
  components: {
    GButtonV2,
    GStars,
  },
  props: {
    cta: {
      type: Object,
      required: true,
    },
    capterra: {
      type: Object,
      default() {
        return {
          score: '4.5',
          total_reviews: '550',
        };
      },
    },
  },
  computed: {
    title() {
      if (typeof this.cta !== 'undefined') {
        return richtext(this.cta.call_to_action, true);
      }
      return '';
    },
    buttonProps() {
      /**
       * Default cta value incase of no ctas in the blok. Mostly will be used for previous g-cta-light version.
       */
      const defaultValue = {
        text: this.cta.button_text,
        analyticsId: this.cta.cta_id,
        modalTarget: '#signupModal',
      };
      if (this.cta.ctas?.length) {
        return (
          convertStoryblokCTAs(this.cta, '', this.cta.cta_id)?.ctas?.[0] ??
          defaultValue
        );
      }

      return defaultValue;
    },
  },
};
