
import richtext, { richtextType } from '~/utils/richtext';
import parseLinkHref from '~/utils/parseLinkHref';

export default {
  name: 'g-button-v2',
  props: {
    modalTarget: {
      type: String,
      default: null,
    },
    link: {
      type: [String, Object],
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    secondaryColor: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'filled',
      validator: type =>
        ['filled', 'outline', 'text', 'underline', 'icon'].includes(type),
    },
    size: {
      type: String,
      default: 'md',
      validator: size => ['sm', 'md', 'lg'].includes(size),
    },
    alignment: {
      type: String,
      required: false,
      default: 'center',
      validator: alignment => ['left', 'center', 'right'].includes(alignment),
    },
    analyticsId: {
      type: String,
      default: null,
    },
    text: {
      type: richtextType,
      default: null,
    },
  },
  computed: {
    computedColor() {
      const themeColors = {
        white: 'purple-50',
        yellow: 'purple-50',
        peach: 'purple-50',
        azure: 'teal-40',
        pink: 'azure-10',
        purple: 'teal-40',
        enterprise: 'teal-40',
      };
      return this.color ?? themeColors[this.theme];
    },
    computedSecondaryColor() {
      const themeColors = {
        white: 'grey-10',
        yellow: 'grey-10',
        peach: 'grey-10',
        azure: 'azure-60',
        pink: 'purple-50',
        purple: 'azure-60',
        enterprise: 'azure-60',
      };
      return this.secondaryColor ?? themeColors[this.theme];
    },
    buttonClasses() {
      const primaryColor = this.computedColor;
      const secondaryColor = this.computedSecondaryColor;
      const hoverColor = this.hoverColor;

      const classes = [
        `g-button-v2`,
        `g-button-v2--${this.size}`,
        `g-button-v2--${this.alignment}`,
      ];

      if (this.modalTarget) {
        classes.push('js-modal-trigger');
      }
      switch (this.type) {
        case 'filled':
          classes.push(
            `text-${secondaryColor}`,
            `bg-${primaryColor}`,
            `border-transparent`,
            `hover:text-${secondaryColor}`, // override _global.pcss a:hover{color}
            `hover:bg-${hoverColor(primaryColor)}`,
            `g-button-v2--filled`
          );
          break;
        case 'outline':
          classes.push(
            `text-${primaryColor}`,
            `border-${primaryColor}`,
            `border-solid`,
            `bg-transparent`,
            `hover:text-${secondaryColor}`,
            `hover:border-${hoverColor(primaryColor)}`,
            `hover:bg-${hoverColor(primaryColor)}`,
            `g-button-v2--outline`
          );
          break;
        case 'text':
          classes.push(
            `text-${primaryColor}`,
            `bg-transparent`,
            `border-none`,
            `hover:text-${hoverColor(primaryColor)}`,
            `g-button-v2--text`
          );
          break;
        case 'underline':
          classes.push(
            `text-${primaryColor}`,
            `border-${primaryColor}`,
            `bg-transparent`,
            `hover:text-${hoverColor(primaryColor)}`,
            `hover:border-${hoverColor(primaryColor)}`,
            `g-button-v2--underline`
          );
          break;
        case 'icon':
          classes.push(
            `text-${primaryColor}`,
            `bg-transparent`,
            `border-none`,
            `hover:text-${hoverColor(primaryColor)}`,
            `p-0`,
            `grow-0`,
            `rounded-full`,
            `g-button-v2--icon`
          );
          break;
      }
      return classes;
    },
    parsedText() {
      return this.text ? richtext(this.text, true) : null;
    },
    parsedLink() {
      // TODO WWW-788 this is very inconsistent and hardly maintainable
      // we should make sure the value is always a string for consistency
      const linkObject = this.parseLinkHref(this.link, true, {
        linkName: 'href',
      });
      if (typeof this.link !== 'object') {
        linkObject.target = this.target;
      }
      return linkObject;
    },
  },
  methods: {
    parseLinkHref,
    hoverColor(color) {
      const [colorType, colorNumber] = color.split('-');
      if (colorNumber > 10) {
        return `${colorType}-${Number(colorNumber) - 10}`;
      }
      return `${colorType}-${Number(colorNumber) + 10}`;
    },
    handleClick() {
      if (this.modalTarget) {
        this.$root.$emit('open-deputy-modal', {
          modalTarget: this.modalTarget,
          id: this.analyticsId,
        });
      } else {
        this.$emit('click');
      }
    },
  },
};
