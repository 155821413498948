
export default {
  name: 'g-checklist',
  components: {
    iconCheckmark: () => import('assets/svg/icons/icon-checkmark.svg'),
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    checkmarkColor: {
      type: String,
      default: null,
    },
    bullet: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    prefixColor() {
      const color = {
        white: 'peach-40',
        yellow: 'peach-40',
        peach: 'peach-40',
        azure: 'peach-40',
        pink: 'yellow-40',
        purple: 'teal-40',
        enterprise: 'teal-40',
      };
      return this.checkmarkColor || color[this.theme];
    },
    textColor() {
      const color = {
        white: 'grey-60',
        yellow: 'grey-60',
        peach: 'grey-60',
        azure: 'grey-10',
        pink: 'grey-10',
        purple: 'grey-10',
        enterprise: 'grey-10',
      };
      return color[this.theme];
    },
  },
};
