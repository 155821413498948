
export default {
  name: 'g-content-reveal',
  props: {
    revealButtonText: {
      type: String,
      default: 'See more',
    },
    // amount to show initially as a percentage of width
    // this allows the component to scale in correct proportions
    revealedAmount: {
      type: String,
      default: '50%',
    },
  },
  data() {
    return {
      currentHeight: this.revealedAmount,
      isRevealed: false,
      isFinishedTransition: false,
      transitionDuration: 500,
    };
  },
  methods: {
    revealMore() {
      this.currentHeight = `${this.$refs.content.offsetHeight}px`;
      this.isRevealed = true;
      window.setTimeout(() => {
        this.isFinishedTransition = true;
      }, this.transitionDuration);
    },
  },
};
