
import GLazyImage from '../g-lazy-image';
import GArrow from '../g-arrow';

export default {
  name: 'g-cards-outline-hover',
  components: {
    GLazyImage,
    GArrow,
  },
  props: {
    cards: {
      type: Array,
      required: true,
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
  },
};
