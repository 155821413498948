
import GArrow from '../g-arrow';
import GLazyImage from '../g-lazy-image';

export default {
  name: 'g-cards-4-cols-icons-links',
  components: {
    GLazyImage,
    GArrow,
  },
  props: {
    cards: {
      type: Array,
      required: true,
      validator: cards => {
        return (
          cards.length >= 1 &&
          // make sure each card has a title
          cards.filter(card => card.title).length === cards.length
        );
      },
    },
    alignment: {
      type: String,
      required: false,
      default: 'left',
      validator: alignment => ['left', 'center'].includes(alignment),
    },
  },
};
