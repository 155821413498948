
import GLazyImage from '../g-lazy-image';
import parseLinkHref from '~/utils/parseLinkHref';
import displayCosts from '~/mixins/displayCosts';
export default {
  name: 'g-add-on-pricing-card',
  components: {
    GLazyImage,
  },
  mixins: [displayCosts],
  props: {
    card: {
      type: Object,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '#FFF9E6',
    },
  },
  methods: {
    parseLinkHref,
    hasImage(image) {
      return image.imagePublicUrl !== '';
    },
  }
};
