
import GButtonV2 from '../g-button-v2';
const BREADCRUMB_URL_REPLACEMENTS = [
  {
    name: 'customers',
    regex: /customers$/,
    url: 'blog?type=customer-story',
  },
];

export default {
  name: 'g-breadcrumb',
  components: { GButtonV2 },
  props: {
    slug: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      region: null,
      slugArray: [],
    };
  },
  computed: {
    textColor() {
      if (this.color) {
        return this.color;
      }
      return ['white', 'yellow', 'peach'].includes(this.theme)
        ? 'grey-60'
        : 'grey-10';
    },
  },
  created: function () {
    const slug = this.slug?.full_slug || this.$store?.state?.story?.full_slug;
    if (slug) {
      this.slugFormatted(slug);
    }
  },
  methods: {
    parseUrlReplacements(link) {
      const replacement = BREADCRUMB_URL_REPLACEMENTS.find(replacement =>
        replacement['regex'].exec(link)
      );
      if (!replacement) {
        return link;
      }

      return link.replace(replacement['regex'], replacement.url);
    },
    slugFormatted: function (slug) {
      // check if a regionalised version and store the region
      const regexFound = /^(au|gb)\//.exec(slug);
      this.region = regexFound ? regexFound[0] : '';
      // split the url and parse to url and name
      const slugArray = slug.replace(/^us\//, '').replace(/\/$/, '').split('/');
      for (let i = 0; i < slugArray.length; i++) {
        if (!/^(au|gb|us)$/.exec(slugArray[i])) {
          let link = slugArray.slice(0, i + 1).join('/');
          // check if the url has a replacement link
          link = this.parseUrlReplacements(link);
          this.slugArray.push({
            link,
            name: slugArray[i].replace(/-/g, ' '),
          });
        }
      }
    },
  },
};
