
import GLazyImage from '../g-lazy-image';
import GButtonV2 from '../g-button-v2';
import { CMS_THEMES } from '~/plugins/componentTheme';

export default {
  name: 'g-card-landscape-rounded-corners',
  components: {
    GLazyImage,
    GButtonV2,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    paragraph: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: false,
      default: '',
    },
    linkText: {
      type: String,
      required: false,
      default: null,
    },
    cmsTheme: {
      type: String,
      required: false,
      default: 'purple',
    },
    tagline: {
      type: String,
      required: false,
      default: null,
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bgColor() {
      const contentHighlightBgColors = {
        [CMS_THEMES.PURPLE]: '#F5F0FF',
        [CMS_THEMES.PINK]: '#FFEBF8',
        [CMS_THEMES.ORANGE]: '#FFF2EF',
        [CMS_THEMES.YELLOW]: '#FFFAEA',
        [CMS_THEMES.GREEN]: '#D9FAF7',
        [CMS_THEMES.BLUE]: '#E0F6FF',
      };

      return contentHighlightBgColors[this.cmsTheme];
    },
  },
};
