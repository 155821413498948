
import GLazyAsset from '../g-lazy-asset';

export default {
  name: 'g-comparison-2-cols',
  components: {
    GLazyAsset,
    iconCheckSolid: () => import('assets/svg/icons/icon-check-solid.svg'),
    iconCrossSolid: () => import('assets/svg/icons/icon-cross-solid.svg'),
    iconAttentionSolid: () =>
      import('assets/svg/icons/icon-attention-solid.svg'),
  },
  props: {
    col1Header: {
      type: String,
      default: '',
    },
    col2Header: {
      type: String,
      default: '',
    },
    col1Items: {
      type: Array,
      default: () => [],
    },
    col2Items: {
      type: Array,
      default: () => [],
    },
  },
};
