
import GStars from '../g-stars';

export default {
  name: 'g-button-store',
  components: {
    GStars,
    AppleDefault: () => import('assets/svg/icons/store-apple.svg'),
    AppleWhite: () => import('assets/svg/icons/store-apple-white.svg'),
    GoogleDefault: () => import('assets/svg/icons/store-google.svg'),
    GoogleWhite: () => import('assets/svg/icons/store-google-white.svg'),
  },
  props: {
    store: {
      type: String,
      required: true,
      validator: store => ['apple', 'google'].includes(store),
    },
    logoColor: {
      type: String,
      default: null,
      validator: color => ['default', 'white'].includes(color),
    },
    appLink: {
      type: String,
      default: 'default',
      validator: appLink => ['default', 'timeClock'].includes(appLink),
    },
    stars: {
      type: Number,
      default: null,
    },
    hideStars: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    region: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      checkedRegion: this.region || this.$store?.state?.region || 'us',
      checkedStars:
        this.stars || Number(this.$store?.state?.stats?.appstore_score),
    };
  },
  computed: {
    stores() {
      return {
        apple: {
          alt: 'Deputy on Apple Store',
          link:
            this.appLink === 'timeClock'
              ? `https://apps.apple.com/${this.checkedRegion}/app/deputy-kiosk-work-time-clock/id622403213`
              : 'https://apps.apple.com/app/deputy-roster-shift-planner/id477070330',
        },
        google: {
          alt: 'Deputy on Google Play',
          link:
            this.appLink === 'timeClock'
              ? `https://play.google.com/store/apps/details?id=com.deputy.android.kiosk&gl=${this.checkedRegion}`
              : 'https://play.google.com/store/apps/details?id=com.deputy.android',
        },
      };
    },
    computedColor() {
      const themeColors = {
        white: 'default',
        yellow: 'default',
        peach: 'default',
        azure: 'white',
        pink: 'white',
        purple: 'white',
        enterprise: 'white',
      };
      return this.logoColor ?? themeColors[this.theme];
    },
    computedWidth() {
      return this.width ?? this.computedColor === 'default' ? '147px' : '120px';
    },
    computedHeight() {
      return this.height ?? this.computedColor === 'default' ? '48px' : '40px';
    },
  },
};
