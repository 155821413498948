
import drift from '~/mixins/drift';

export default {
  name: 'drift',
  mixins: [drift],
  props: {
    init: {
      type: Boolean,
      default: false,
    },
  },
};
