
import GChecklist from '../g-checklist';
import GButtonV2 from '../g-button-v2';
import GLazyImage from '../g-lazy-image';

export default {
  name: 'g-card-to-modal-target',
  components: { GChecklist, GButtonV2, GLazyImage },
  props: {
    headline: {
      type: String,
      required: true,
    },
    subline: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    ctaColor: {
      type: String,
      default: 'purple',
    },
    ctaTarget: {
      type: String,
      default: '#bookADemo',
    },
    ctaText: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      required: true,
    },
  },
};
