
import isEmpty from 'lodash/isEmpty';
import { THEME } from '~/plugins/componentTheme';

export const imageAlignmentOptions = ['left', 'right'];

export default {
  name: 'g-2-cols-img-copy',
  components: {
    GLazyImage: () => import('../g-lazy-image'),
    GStatsQuote: () => import('../g-stats-quote'),
    GButtonV2: () => import('../g-button-v2'),
    GLottieAnimation: () => import('../g-lottie-animation'),
    GMedia: () => import('../g-media'),
    GHighlight: () => import('../g-highlight'),
  },
  props: {
    tag: {
      type: String,
      required: false,
      default: '',
    },
    tagline: {
      type: String,
      required: false,
      default: '',
    },
    richtextHeadline: {
      type: String,
      default: null,
    },
    highlightColor: {
      type: String,
      default: null,
    },
    headline: {
      type: String,
      required: true,
      default: '',
    },
    paragraph: {
      type: String,
      required: false,
      default: '',
    },
    ctaType: {
      type: String,
      required: false,
      default: 'link',
    },
    linkText: {
      type: String,
      required: false,
      default: 'Learn more',
    },
    linkUrl: {
      type: String,
      required: false,
      default: '',
    },
    target: {
      type: String,
      required: false,
      default: '',
    },
    media: {
      type: Object,
      default: null,
    },
    image: {
      type: Object,
      required: false,
      default: null,
    },
    animationUrl: {
      type: String,
      required: false,
      default: null,
    },
    animationPlaceholder: {
      type: Object,
      required: false,
      default: null,
    },
    videoUrl: {
      type: String,
      required: false,
      default: null,
    },
    imageAlignment: {
      type: String,
      validator: imageAlignment =>
        imageAlignmentOptions.includes(imageAlignment),
      default: 'right',
    },
    quote: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    quoteColor: {
      type: String,
      default: null,
    },
    arrowColor: {
      type: String,
      default: 'purple',
    },
    buttonOutlineColor: {
      type: String,
      default: 'white',
    },
  },
  data() {
    return {
      homepageLayout: !!this.quoteColor,
    };
  },
  computed: {
    buttonStyles() {
      if (this.buttonOutlineColor === 'spark-outline') {
        return {
          type: 'outline',
          color: 'peach-40',
        };
      }
      return {
        type: 'filled',
        color: 'azure-10',
        secondaryColor: 'purple-50',
      };
    },
    isWhiteTheme() {
      return this.theme === THEME.WHITE;
    },
    desktopButtonStyles() {
      if (this.isWhiteTheme) {
        return null;
      }

      return {
        color: this.textThemeColor,
      };
    },
    hasTag() {
      return this.tag !== '';
    },
    hasLink() {
      return this.linkUrl !== '' || this.ctaType !== 'link';
    },
    hasQuote() {
      return !isEmpty(this.quote);
    },
    getLinkText() {
      return this.linkText !== '' ? this.linkText : this.linkUrl;
    },
    anchor() {
      return this.headline.toLowerCase().replace(/[^a-z0-9]/g, '-');
    },
    mediaProps() {
      if (!this.media) {
        return null;
      }
      return {
        image: this.media.image,
        animationUrl: this.media.animation?.filename,
        mobileAnimationUrl: this.media.mobileAnimation?.filename,
        placeholderImage: this.media.placeholder,
        videoEmbedUrl: this.media.videoEmbedUrl,
        videoThumbnail: this.media.videoThumbnail,
        playVideoOption: this.media.playVideoOption,
        imageSizeSm: 414,
        imageSizeMd: 767,
        imageSizeLg: 991,
        imageSizeXl: 3000,
        imageSizeXxl: 5000,
      };
    },
  },
};
