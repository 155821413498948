
import {
  bugcrowdEmbedScript,
  typeformEmbedScript,
  newTypeformEmbedScript,
} from './definitions';

// Regular expression to match content inside <script> tags without src attribute
const inlineScriptRegex =
  /<script\b(?![^>]*\bsrc=)[^>]*>([\s\S]*?)<\/script>/gi;
const srcScriptRegex = /<script\b[^>]*\bsrc="([^"]*)"[^>]*><\/script>/gi;

export default {
  name: 'g-blog-raw-content',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      scriptUrl: '',
    };
  },
  computed: {
    inlineScripts() {
      const scriptMatches =
        this.content.raw_content.match(inlineScriptRegex) || [];
      const scriptContents = scriptMatches.map(match =>
        match.replace(inlineScriptRegex, '$1')
      );

      return scriptContents;
    },
    srcScripts() {
      const scriptMatches =
        this.content.raw_content.match(srcScriptRegex) || [];
      const scriptContents = scriptMatches.map(match =>
        match.replace(srcScriptRegex, '$1')
      );

      return scriptContents;
    },
    rawContentWithoutInlineScripts() {
      return this.content.raw_content.replace(inlineScriptRegex, '');
    },
    isBugcrowdEmbed() {
      return JSON.stringify(this.content.raw_content).includes(
        'data-bugcrowd-program'
      );
    },
  },
  methods: {
    scriptTag() {
      // typeform has a new script for the new embed format
      const typescriptNewTags = ['data-tf-live', 'data-tf-widget'];
      const foundTypescriptNewTags = typescriptNewTags.some(tag =>
        JSON.stringify(this.content.raw_content).includes(tag)
      );
      if (foundTypescriptNewTags) {
        this.scriptUrl = newTypeformEmbedScript;
        return true;
      }
      // typeform script needs to load after the page has loaded then will replace the <div> by an <iframe>
      if (JSON.stringify(this.content.raw_content).includes('typeform')) {
        this.scriptUrl = typeformEmbedScript;
        return true;
      }
      if (this.isBugcrowdEmbed) {
        this.scriptUrl = bugcrowdEmbedScript;
        return true;
      }
      return false;
    },
  },
};
