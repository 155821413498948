
import GSpacer from '../g-spacer';
import GButtonV2 from '../g-button-v2';
import GContainer from '../g-container';
import GStars from '../g-stars';
import richtext from '~/utils/richtext';

export default {
  name: 'g-cta-dark',
  components: {
    GSpacer,
    GButtonV2,
    GStars,
    GContainer,
  },
  props: {
    cta: {
      type: Object,
      required: true,
    },
    capterra: {
      type: Object,
      default() {
        return {
          score: '4.5',
          total_reviews: '550',
        };
      },
    },
  },
  computed: {
    title() {
      if (typeof this.cta !== 'undefined') {
        return richtext(this.cta.call_to_action);
      }
      return '';
    },
    ctaStyleClass() {
      return `g-cta-dark__inner--${this.cta.call_to_action_style}`;
    },
  },
};
