
import GLazyImage from '../g-lazy-image';
import GLazyAsset from '../g-lazy-asset';

export default {
  name: 'g-cards-4-col-links',
  components: {
    GLazyImage,
    GLazyAsset,
  },
  props: {
    cards: {
      type: Array,
      required: true,
      validator: cards => cards.length > 0,
    },
  },
  methods: {
    getLinkTitle(href) {
      if (this.isLinkedin(href)) {
        return 'Linkedin profile';
      } else {
        return 'External link';
      }
    },
    isLinkedin(href) {
      return href.includes('linkedin.com');
    },
    getLinkTarget(href) {
      return href.includes('deputy.com') ? '_self' : '_blank';
    },
    getLinkIconPath(href) {
      if (this.isLinkedin(href)) {
        return 'icons/icon-linkedin--round';
      }
      return 'icons/icon-external-link--round';
    },
  },
};
