
import GColourHeadingCard from './g-colour-heading-card';

export default {
  name: 'g-colour-heading-cards',
  components: {
    GColourHeadingCard,
  },
  props: {
    cards: {
      type: Array,
      required: true,
      validator: cards => cards.length <= 4,
    },
    showNumbers: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      colours: [
        'rgb(208, 38, 48)',
        'rgb(25, 128, 162)',
        'rgb(62, 39, 183)',
        'rgb(159, 12, 102)',
      ],
      coloursLight: [
        'rgb(254, 231, 230)',
        'rgb(241, 249, 248)',
        'rgb(246, 245, 251)',
        'rgb(255, 248, 252)',
      ],
    };
  },
  computed: {
    cardsLength() {
      const cardLength = this.cards.length;
      return {
        '--card-md-length': cardLength === 3 ? 3 : 2,
        '--card-lg-length': cardLength === 3 ? 3 : 4,
      };
    },
  },
};
