
import cmsComponent from 'mixins/cmsComponent';

export default {
  name: 'glossary-terms',
  components: {
    cmsRichtext: () => import('../cms-richtext.vue'),
    cmsHeader: () => import('../cms-header'),
    cmsLazyImage: () => import('../cms-lazy-image'),
    cmsButtonV2: () => import('../cms-button-v2'),
  },
  mixins: [cmsComponent],
  data() {
    return {
      componentHeader: ['cms-header'],
    };
  },
};
