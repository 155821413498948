
import GLazyImage from '../g-lazy-image';

export default {
  name: 'g-card-outline-round',
  components: { GLazyImage },
  props: {
    image: {
      type: Object,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
    hideOnMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isExternalLink() {
      return !this.link.includes('deputy.com') && !this.link.startsWith('/');
    },
  },
};
