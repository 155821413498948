
import GLazyImage from '../g-lazy-image';

export default {
  name: 'g-card-inline',
  components: {
    GLazyImage,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isMouseOver: false,
    };
  },
};
