
import GAddOnPricingCard from '../g-add-on-pricing-card';
export default {
  name: 'g-add-on-pricing',
  components: { GAddOnPricingCard },
  props: {
    add_ons: {
      type: Array,
      required: true,
    },
  },
};
