
export const arrowColors = ['purple-50', 'purple-30', 'spark', 'white'];

export default {
  name: 'g-arrow',
  components: {
    ArrowIcon: () => import('assets/svg/icons/icon-arrow.svg'),
  },
  props: {
    color: {
      type: String,
      validator: color => arrowColors.includes(color),
      default: 'purple-50',
    },
    width: {
      type: String,
      default: '29',
    },
    height: {
      type: String,
      default: '17',
    },
  },
  computed: {
    colorCheck() {
      return `g-arrow--${this.color}`;
    },
  },
};
