
import GLazyImage from '../g-lazy-image';
import GButtonV2 from '../g-button-v2';
export default {
  name: 'g-card-outline-squiggle',
  components: {
    GLazyImage,
    GButtonV2,
  },
  props: {
    headline: {
      type: String,
      required: true,
      default: '',
    },
    subline: {
      type: String,
      required: true,
      default: '',
    },
    ctaLink: {
      type: String,
      required: true,
      default: '',
    },
    ctaText: {
      type: String,
      required: true,
      default: '',
    },
    image: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
