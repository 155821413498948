
import GLazyImage from '../g-lazy-image';
import GArrow from '../g-arrow';
export const cardTypes = ['shadow', 'border'];

export default {
  name: 'g-card-outline',
  components: {
    GLazyImage,
    GArrow,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    url: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      validator: type => cardTypes.includes(type),
      default: 'shadow',
    },
    hide: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideImage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    cardClasses() {
      return `g-card-outline--${this.type}`;
    },
  },
  methods: {
    parseTarget(url) {
      const regex = new RegExp('(http|https)');
      return regex.test(url) ? '_blank' : '_self';
    },
  },
};
